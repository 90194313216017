<template>
    <!-- <div class="row row--15 service-wrapper"> -->
    <div class="row row--15">
        <div class="col-lg-3 col-md-6 col-sm-6 col-12"
             v-for="(service, index) in serviceList"
             :key=index>
            <div :class="`service ${serviceStyle} ${checkTextAlign}`"
                 data-aos="fade-up"
                 data-aos-delay="70">
                <div class="icon">
                    <!-- <div class="line"/> -->
                    {{ service.icon }}
                </div>
                <div class="content">
                    <h4 class="title">
                        <!-- <router-link to="/service-details" v-html="service.title"/> -->
                        {{service.title}}
                    </h4>
                    <p class="description b1 color-gray mb--0" v-html="service.description"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServiceFour',
        props: {
            serviceStyle: {
                type: String
            },
            textAlign: {
                type: String,
                default: 'left'
            }
        },
        computed: {
            checkTextAlign() {
                if (this.textAlign === 'left') return 'text-start';
                else if (this.textAlign === 'center') return 'text-center';
                else if (this.textAlign === 'end') return 'text-end';
                else return '';
            },
            serviceList() {
                return [
                    {
                        icon: '1',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem1.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem1.description'),
                    },
                    {
                        icon: '2',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem2.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem2.description'),
                    },
                    {
                        icon: '3',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem3.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem3.description'),
                    },
                    {
                        icon: '4',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem4.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem4.description'),
                    },
                    {
                        icon: '5',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem5.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem5.description'),
                    },
                    {
                        icon: '6',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem6.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem6.description'),
                    },
                    {
                        icon: '7',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem7.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem7.description'),
                    },
                    {
                        icon: '8',
                        title: this.$t('businessConsulting.serviceFourList.serviceFourItem8.title'),
                        description: this.$t('businessConsulting.serviceFourList.serviceFourItem8.description'),
                    }
                ]
            }
        }
    }
</script>