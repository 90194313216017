module.exports = {
  // ヘッダー
  header: {
    businessConsulting: "ホーム",
    digitalAgency: "会社概要",
    portfolioFullWidth: "開発実績",
    testimonial: "採用情報",
    contact: "お問い合わせ",
    business: "事業内容"
  },
  // ホーム
  businessConsulting: {
    subtitle: "ソフトウェアテクノロジー開発",
    title: "大連思睿众合情報技術有限公司",
    description1: "顧客価値志向",
    description2: "卓越品質の技術で",
    serviceList: {
      serviceItem1:{
        title: 'カスタムソフトウェア開発',
        description: '企業向けソリューションまたはモバイルアプリケーションに関係なく、お客様のニーズに合わせたカスタムソフトウェア開発を提供し、独自のビジネス要件を満たします。'        
      },
      serviceItem2:{
        title: 'Webアプリケーション開発',
        description: '最新のフロントエンドおよびバックエンドテクノロジーを組み合わせ、驚異的なオンラインエクスペリエンスを提供するために、強力なWebアプリケーションを設計および開発します。'        
      },
      serviceItem3:{
        title: 'モバイルアプリケーション開発',
        description: '当社のモバイルアプリケーション開発の専門知識を活用して、AndroidおよびiOSアプリケーションの高機能化を実現し、お客様のサービスをいつでもどこでも利用できるようにします。'        
      },
      serviceItem4:{
        title: 'テクニカルコンサルティングおよびソリューション',
        description: '当社の専門チームが技術コンサルティングを提供し、ビジネスニーズに最適な技術ソリューションの選択をサポートし、ビジネス目標を達成します。'        
      },
      serviceItem5:{
        title: 'システム統合および既存アプリケーションのアップグレード',
        description: '異なるシステムを統合するか、既存のアプリケーションをアップグレードする必要がある場合、専門チームがスムーズな過渡と効率的なシステムアップグレードをサポートします。'        
      },
      serviceItem6:{
        title: 'ユーザーエクスペリエンスデザイン',
        description: '当社はユーザーエクスペリエンスを最優先にし、革新的なデザインとユーザーフレンドリーなインターフェースでアプリケーションを向上させます。優れたユーザーエクスペリエンスは、ユーザーを引き寄せ、維持するための鍵と信じています。'        
      },
    },
    sectionTitle:{
      title: 'なぜ私たちを選ぶのか',
      description: 'デジタル化の時代において、私たちを選ぶことは卓越した専門知識、先端技術力、お客様中心のサービスを選ぶことを意味します。業界をリードするパートナーと連携し、高度にカスタマイズされたソリューションを提供し、プロジェクトの優れた提供とセキュリティと信頼性を確保します。私たちは現在のニーズだけでなく、持続可能な成長と社会的責任にも取り組んでいます。私たちを選ぶことは、成功、革新、卓越と共に歩むことを意味します。',
    },
    serviceFourList:{
      serviceFourItem1:{
        title: '卓越した専門知識',
        description: '多年にわたる深い業界知識を持ち、優れたソリューションを提供します。'        
      },
      serviceFourItem2:{
        title: '革新的な技術力',
        description: '最新の技術を継続的に採用し、革新的なソリューションを構築します。'        
      },
      serviceFourItem3:{
        title: 'お客様中心のサービス',
        description: 'お客様の満足度は私たちの最優先事項であり、お客様のニーズを満たし、フィードバックに積極的に対応します。'        
      },
      serviceFourItem4:{
        title: '強力なパートナーシップ',
        description: '業界のトップパートナーと協力し、幅広いサポートとリソースを提供します。'        
      },
      serviceFourItem5:{
        title: '高度にカスタマイズされたソリューション',
        description: 'お客様の要件を深く理解し、カスタマイズされたソリューションを提供します。'        
      },
      serviceFourItem6:{
        title: '卓越したプロジェクトデリバリー',
        description: '常にプロジェクトを時間通りに納品し、高品質な成果を保証します。'        
      },
      serviceFourItem7:{
        title: 'セキュリティと信頼性',
        description: 'データとビジネスのセキュリティを確保するために尽力します。'        
      },
      serviceFourItem8:{
        title: '持続可能な成長の取り組み',
        description: '環境と社会的責任に焦点を当て、持続可能なソリューションを積極的に追求します。'        
      },
    },
    timelineDataList: {
      timelineDataItem1:{
        title: '知識',
        description: 'この段階では、お客様の要件を深く理解し、関連する情報と知識を収集および整理します。私たちは問題の深い理解が洞察力の源であると信じており、プロジェクトを開始する前に必要な知識と背景を持っていることを確保します。これにより、より賢く、より創造的なソリューションを提供できます。'        
      },
      timelineDataItem2:{
        title: '作業',
        description: 'この段階は、チームが作業を開始するためのフェーズです。当社の専門家は知識を実際の行動に変え、タスクと作業を展開します。効果的な協力と技術専門知識により、すべての作業が計画通りに進行し、最良の結果を実現します。'        
      },
      timelineDataItem3:{
        title: 'ソリューション',
        description: 'この段階では、要件と目標を満たすソリューションを綿密に構築します。専門家チームは作業成果物を具体的なソリューションに変え、これらのソリューションは卓越した価値と持続的な競争優位性を提供するように設計されています。私たちは、すべての課題には新しいソリューションが待っていると信じています。'        
      },
      timelineDataItem4:{
        title: 'プロセス',
        description: 'この段階は、プロジェクトのさまざまな側面を精密に設計および管理するプロセスの重要な部分です。透明性とコミュニケーションは非常に重要であり、プロジェクトの進行状況を常に把握できるように、お客様との緊密な連絡を保ちます。'        
      },
    },
    partners: "パートナー",
    partnerDescription: "パートナーシップでは、私たちは技術的なパートナーだけでなく、戦略的なパートナーでもあります。パートナーシップを通じて戦略計画を策定し、リソースと知識を共有し、変化する市場ニーズに対応します。パートナーシップの専門知識と業界洞察力により、カスタマイズされたソリューションを提供し、顧客のビジネス目標を達成し、効率を向上させ、コストを削減します。大規模企業や中小企業向けに、パートナーシップは私たちの成功の鍵の一つであり、強力なITエコシステムを構築し、顧客により多くの価値を提供します。",
  },
  // About Us
  digitalAgency: {
    title: "企業文化",
    subTitle1: "責任",
    subTitle2: "革新",
    subTitle3: "超越",
    subTitle4: "共有",
    leftContent1: "当社の企業文化では、責任が社会、環境、お客様への約束を表しています。私たちは道徳的で誠実であり、ビジネス運営が持続可能で透明であり、環境にやさしく、お客様に対して責任を持っています。責任は当社の支えであり、未来に向けてより良い明日を創造するための約束です。",
    leftContent2: "革新は、絶えず進化し続ける情熱的な探求心を表しています。私たちは過去の成果に満足せず、新たな可能性を探求する勇気を持っています。革新は製品、サービス、ビジネスプロセスに浸透し、変化の速い市場で競争力を維持する力となっています。",
    leftContent3: "これらの4つのテーマ、責任、革新、超越、共有は、当社の企業文化の一部だけでなく、毎日の業務の指針でもあります。これらは私たちがより優れた企業になり、顧客に優れたソリューションを提供し、世界社会に積極的な影響を与えるためのものです。私たちはこの文化を誇りに思い、今後も当社の未来を形作ると信じています。",
    rightContent1: "当社の企業文化では、責任が基盤です。企業として、株主に価値を提供するだけでなく、社会と環境に対する責任も負っています。持続可能なビジネス実践を積極的に採用し、リソース消費を削減し、炭素排出を削減し、コミュニティサポート活動に参加しています。お客様に対する責任は、常に優れたサービスを提供し、彼らの要求を満たし、フィードバックに積極的に対応することを意味します。責任を持つことで、持続的な繁栄と社会と環境への積極的な影響をもたらすと考えています。",
    rightContent2: "革新は当社の企業文化の原動力です。従業員に現状に挑戦し、革新的なソリューションを見つけるよう奨励しています。革新は成功の鍵であると信じており、常に研究開発と技術に投資して、製品とサービスがリードを続けることを確保しています。創造的な思考を育て、従業員に新しいアイデアと方法を提供するよう奨励しており、お客様の変化するニーズに対応しています。革新は当社のDNAに浸透しており、持続的な成長の基盤です。",
    rightContent3: "超越は当社の企業文化の核心です。私たちは平凡に満足せず、卓越を追求します。従業員には自分自身の限界に挑戦し、スキルと能力を向上させ、より高い目標を達成するよう奨励しています。私たちは、自分自身を挑戦し、超越するプロセスでのみ、本当の成功を達成できると信じています。超越は私たち自身への要求であり、顧客への優れたサービスとソリューションを提供するための約束です。",
    rightContent4: "共有は当社の企業文化の重要な要素です。協力と知識共有は成功の鍵であると信じています。従業員同士の協力を奨励し、パートナーと密接な関係を築き、共通の目標を達成します。最良の実践と経験を積極的に共有し、チームの成長と顧客の成功を促進します。共有は行動だけでなく、文化でもあり、一緒に前進し、価値を共に創造します。",
  },
  // ポートフォリオ
  portfolioFullWidth: {
    title: "インターネット+業界のソリューション",
    subTitle: "INTERNET + INDUSTRY SOLUTION",
    portfolioItems: {
      item1: {
        title: 'スペースを楽しむ',
        subtitle: 'ヨガプラットフォーム',
        category: 'ヨガプラットフォーム',
        body: `<p>専門のヨガプラットフォームで、加盟店の参加、予約、支払いが簡単にできるようになりました。ユーザーフレンドリーなインターフェースと高度な予約システムを組み合わせて、ヨガスタジオの運営をサポートしました。</p>
        <p>当社のソリューションは、ヨガ愛好家とスタジオオーナーのニーズを満たすために設計されており、柔軟性のある予約オプション、支払いの自動化、ヨガクラスのライブストリーミングなど、革新的な機能を提供しています。</p>`,
      },
      item2: {
        title: 'SeMS管理システム',
        subtitle: '飛行安全保障管理システム',
        category: '飛行安全保障管理システム',
        body: `<p>航空安全保障管理システム（SeMS）は、世界の民間航空安全保障管理の発展傾向に適応するために提案された民間航空安全保障レベルを高める管理方法であり、この管理方法はシステム安全の観点から、法規管理の基礎の上で現行の航空安全保障管理システムを統合する。
        民航安全検査SeMS品質監察電子システムは民航安全保障品質監察業務に向けた情報収集と展示システムであり、クラウドサービス技術を用いて品質監察データに対して移動収集、伝送、貯蔵、操作とメンテナンスを行い、データモデルを通じて各種データに対して分析と展示を行い、重点的に監察業務記録の電子化、品質安全管理情報化、データ処理分析の知能化を実現し、会社の現在の品質監査の仕事の紙台帳問題と安全管理の仕事の実際の需要を解決して、管理層の指揮と政策決定の補助に役立ちます。同時にシステムはモバイルapp機能をサポートし、ハンドヘルドモバイル端末とコンピュータ端末のリアルタイム入力、受信、データ検索を実現する。
        システムは完備したシステム管理ツールを有し、ユーザー自身のメンテナンスに便利を提供する、システムは拡張とシステム統合を容易にする、システムは、クラウドシステムのデータを共同で管理するための情報セキュリティを保障するための完全なセキュリティ保護システムを備えている。
        製品には、web端末管理システムとモバイル端末オペレーティングシステムが含まれる。`,
      },
      item3: {
        title: 'モバイルバンキング',
        subtitle: '金融アプリケーション',
        category: '金融アプリケーション',
        body: `<p>モバイルバンキングアプリは、ユーザーが簡単に銀行口座の残高や取引履歴を見ることができます。ユーザーは資金の流れをリアルタイムで監視でき、アカウントの財務状態が常に制御されていることを確認できます`,
      },
    },
  },
  // 採用情報
  testimonial: {
    title: "採用情報",
    testimonialData: {
      item1: {
        image: 'job',
        name: 'プロジェクトマネージャー',
        designation: '日本語対応',
        location: 'CO Miego, AD, USA',
        description: '学士以上の学位、関連する専門知識、3年以上のプロジェクトマネジメント経験、流暢な日本語コミュニケーション能力、優れたチームワークおよびリーダーシップスキル',
      },
      item2: {
        image: 'job',
        name: 'プロジェクトリーダー',
        designation: '日本語対応',
        location: 'バングラデシュ',
        description: '学士以上の学位、関連する専門知識、2年以上のプロジェクトコーディネーションまたはチームリーダー経験、流暢な日本語コミュニケーション能力、優れた問題解決と意思決定能力',
      },
      item3: {
        image: 'job',
        name: '開発エンジニア',
        designation: '日本語対応',
        location: 'ポーランド',
        description: 'コンピュータサイエンスまたは関連専門分野の学士以上の学位、2年以上のソフトウェア開発経験、Java、JavaScriptなどのプログラミング言語に熟練、流暢な日本語コミュニケーション能力',
      },
      item4: {
        image: 'job',
        name: 'インターン',
        designation: '日本語対応',
        location: 'ポーランド',
        description: '学士または修士課程の学生、IT分野に興味、基本的な日本語コミュニケーション能力、優れた学習能力とチーム協力精神',
      },
      item5: {
        image: 'job',
        name: 'プロジェクトマネージャー',
        designation: '国内',
        location: 'CO Miego, AD, USA',
        description: '学士以上の学位、関連する専門知識、3年以上のプロジェクトマネジメント経験、優れたコミュニケーションおよび調整能力、優れたチームリーダーシップおよび問題解決能力',
      },
      item6: {
        image: 'job',
        name: 'プロジェクトリーダー',
        designation: '国内',
        location: 'バングラデシュ',
        description: '学士以上の学位、関連する専門知識、2年以上のプロジェクトコーディネーションまたはチームリーダー経験、優れた組織力と時間管理スキル、優れたチームワークスピリット',
      },
      item7: {
        image: 'job',
        name: '開発エンジニア',
        designation: '国内',
        location: 'ポーランド',
        description: 'コンピュータサイエンスまたは関連専門分野の学士以上の学位、2年以上のソフトウェア開発経験、フロントエンドまたはバックエンド開発スキルの熟練、優れたプログラミングおよび問題解決能力',
      },
      item8: {
        image: 'job',
        name: 'インターン',
        designation: '国内',
        location: 'ポーランド',
        description: '学士または修士課程の学生、IT業界に強い興味、優れた学習能力とコミュニケーションスキル、積極的な労働態度とチームプレイスピリット',
      },
    }
  },
  // お問い合わせ
  contact: {
    phone: {
      title: "お問い合わせ先",
      phoneName1: "韓 磊",
      phoneName2: "宮 明哲"
    },
    email: {
      title: "メールアドレス",
      emailAddress: "lei.han@dl-dev.net",
    },
    address: {
      title: "所在地",
      address: "大連市高新園区七賢嶺愛賢街10号大連設計城A座412",
    },
    partners: {
      title:"提携会社",
      partnerName1:"東森テクノロジー株式会社",
      partnerName2:"蘇州派維斯信息科技有限公司",
    },
  },
  // 底部
  bottom: {
    businessConsulting: "ホーム",
    digitalAgency: "会社概要",
    portfolioFullWidth: "ポートフォリオ",
    testimonial: "採用情報",
    contact: "お問い合わせ",
    business: "事業内容"
  },
  // 共通
  common: {
    contactUs: "連絡先",
    return: "戻る"
  }
};
