export default {
    computed: {
        portfolioItems() {
            return [
                {
                    id: 1,
                    portfolioImage: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai1.png`),
                    image: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai1.png`),
                    title: this.$t('portfolioFullWidth.portfolioItems.item1.title'),
                    subtitle: this.$t('portfolioFullWidth.portfolioItems.item1.subtitle'),
                    date: '30 May 2022',
                    client: 'Rainbow Themes',
                    category: this.$t('portfolioFullWidth.portfolioItems.item1.category'),
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: this.$t('portfolioFullWidth.portfolioItems.item1.body'),
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai1.png`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai1.png`),
                        imageTwo: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai2.png`),
                        imageThree: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai3.png`),
                        imageFour: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai4.png`),
                        imageFive: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai5.png`),
                        imageSix: require(`@/assets/images/portfolio/yujiapingtai/yujiapingtai6.png`)
                    }
                },
             //    {
             //        id: 2,
             //        portfolioImage: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng1.png`),
             //        image: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng1.png`),
             //        title: '口袋商城',
             //        subtitle: '电商平台',
             //        date: '01 March 2022',
             //        client: 'Rainbow Themes',
             //        category: '电商平台',
             //        awards: ['2020 Design beautiful apps Finale Awards'],
             //        excerpt: 'Design beautiful apps.',
             //        body: `<p>口袋商城移动端包含微信小程序以及手机APP两部分。是一个便捷购物的电商平台。</p>`,
             //        btn: {
             //            buttonText: 'View Projects',
             //            link: '/'
             //        },
             //        largeImage: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng1.png`),
             //        subContent: {
             //            title: 'Digital Marketing.',
             //            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        subContent2: {
             //            title: 'App Development.',
             //            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        subContent3: {
             //            title: 'Solution.',
             //            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        gallery: {
             //            imageOne: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng1.png`),
             //            imageTwo: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng2.png`),
             //            imageThree: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng3.png`),
             //            imageFour: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng4.png`),
             //            imageFive: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng5.png`),
             //            imageSix: require(`@/assets/images/portfolio/koudaishangcheng/koudaishangcheng6.png`)
             //        }
             //    },
             //    {
             //        id: 3,
             //        portfolioImage: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai1.png`),
             //        image: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai1.png`),
             //        title: '墨墨慧充',
             //        subtitle: '充电平台',
             //        date: '01 March 2022',
             //        client: 'Rainbow Themes',
             //        category: '充电平台',
             //        awards: ['2020 Design beautiful apps Finale Awards'],
             //        excerpt: 'Design beautiful apps.',
             //        body: `<p>墨墨慧充是基于阿里云的监控管理平台，平台实现了对充电站（桩）的实时监控、用户中心（用户管理、车辆管理）、设备管理（机构管理、场站管理、电桩管理、计费管理、远程升级管理、白名单管理）、充电记录查询、远程控制、充电数据统计分析、充电结算等功能，同时可支持与第三方系统平台的数据交换。
             //        多语言操作界面，支持中文、英文、韩文、俄文、西班牙文，方便海外客户使用。`,
             //        btn: {
             //            buttonText: 'View Projects',
             //            link: '/'
             //        },
             //        largeImage: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai1.png`),
             //        subContent: {
             //            title: 'Digital Marketing.',
             //            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        subContent2: {
             //            title: 'App Development.',
             //            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        subContent3: {
             //            title: 'Solution.',
             //            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
             //        },
             //        gallery: {
             //            imageOne: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai1.png`),
             //            imageTwo: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai2.png`),
             //            imageThree: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai3.png`),
             //            imageFour: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai4.png`),
             //            imageFive: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai5.png`),
             //            imageSix: require(`@/assets/images/portfolio/chongdianpingtai/chongdianpingtai6.png`)
             //        }
             //    },
                {
                    id: 4,
                    portfolioImage: require(`@/assets/images/portfolio/sems/sems1.png`),
                    image: require(`@/assets/images/portfolio/sems/sems1.png`),
                    title: this.$t('portfolioFullWidth.portfolioItems.item2.title'),
                    subtitle: this.$t('portfolioFullWidth.portfolioItems.item2.subtitle'),
                    date: '30 May 2022',
                    client: 'Rainbow Themes',
                    category: this.$t('portfolioFullWidth.portfolioItems.item2.category'),
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: this.$t('portfolioFullWidth.portfolioItems.item2.body'),
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/sems/sems1.png`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/sems/sems1.png`),
                        imageTwo: require(`@/assets/images/portfolio/sems/sems2.png`),
                        imageThree: require(`@/assets/images/portfolio/sems/sems3.png`),
                        imageFour: require(`@/assets/images/portfolio/sems/sems4.png`),
                        imageFive: require(`@/assets/images/portfolio/sems/sems5.png`),
                        imageSix: require(`@/assets/images/portfolio/sems/sems6.png`),
                    }
                },
                {
                    id: 5,
                    portfolioImage: require(`@/assets/images/portfolio/bank/bank1.png`),
                    image: require(`@/assets/images/portfolio/bank/bank1.png`),
                    title: this.$t('portfolioFullWidth.portfolioItems.item3.title'),
                    subtitle: this.$t('portfolioFullWidth.portfolioItems.item3.subtitle'),
                    date: '30 May 2022',
                    client: 'Rainbow Themes',
                    category: this.$t('portfolioFullWidth.portfolioItems.item3.category'),
                    awards: ['2020 Design beautiful apps Finale Awards'],
                    excerpt: 'Design beautiful apps.',
                    body: this.$t('portfolioFullWidth.portfolioItems.item3.body'),
                    btn: {
                        buttonText: 'View Projects',
                        link: '/'
                    },
                    largeImage: require(`@/assets/images/portfolio/bank/bank1.png`),
                    subContent: {
                        title: 'Digital Marketing.',
                        subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent2: {
                        title: 'App Development.',
                        subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    subContent3: {
                        title: 'Solution.',
                        subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                    },
                    gallery: {
                        imageOne: require(`@/assets/images/portfolio/bank/bank1.png`),
                        imageTwo: require(`@/assets/images/portfolio/bank/bank2.png`),
                        imageThree: require(`@/assets/images/portfolio/bank/bank3.png`),
                        imageFour: require(`@/assets/images/portfolio/bank/bank4.png`),
                        imageFive: require(`@/assets/images/portfolio/bank/bank5.png`),
                        imageSix: require(`@/assets/images/portfolio/bank/bank6.png`),
                    }
                }
            ]
        }
    }
}