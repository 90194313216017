<template>
    <Layout>
        <!-- Start Elements Area -->
        <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mb--10">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('testimonial.title')"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial"/>
                    </div>
                </div>
            </div>
        </div>
        <!-- End Elements Area -->

        <!-- <Separator/> -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-elements-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Testimonial"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial" :testimonial-style="2"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Elements Area -->

        <!-- <Separator/> -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-elements-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Testimonial"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-12"
                         v-for="(testimonial, index) in testimonialData"
                         :key="index">
                        <Testimonial :testimonial="testimonial" :testimonial-style="3"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Elements Area -->

        <!-- <Separator/> -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-elements-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Testimonial"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData4" :testimonial-style="4"/>
            </div>
        </div> -->
        <!-- End Elements Area -->
        <!-- <Separator/> -->


        <!-- Start Elements Area -->
        <!-- <div class="rwt-elements-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Testimonial"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData5" :testimonial-style="5"/>
            </div>
        </div> -->
        <!-- End Elements Area -->
    </Layout>
</template>

<script>
    import Layout from '../components/common/Layout'
    import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
    import Separator from '../components/elements/separator/Separator'
    import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
    import Testimonial from "../components/elements/testimonial/Testimonial";

    export default {
        name: 'TestimonialPage',
        components: {Testimonial, SectionTitle, Separator, Layout, Breadcrumb},
        // data() {
        //     return {
        //         // testimonialData: [
        //         //     {
        //         //         image: 'job',
        //         //         name: '项目经理',
        //         //         designation: '对日',
        //         //         location: 'CO Miego, AD, USA',
        //         //         description: '本科或以上学历，相关专业背景，3年以上项目管理经验，流利的日语沟通能力，出色的团队合作和领导能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '项目组长',
        //         //         designation: '对日',
        //         //         location: 'Bangladesh',
        //         //         description: '本科或以上学历，相关专业背景，2年以上项目协调或团队领导经验，流利的日语沟通能力，良好的问题解决和决策能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '开发工程师',
        //         //         designation: '对日',
        //         //         location: 'Poland',
        //         //         description: '计算机科学或相关专业背景，2年以上软件开发经验，熟练掌握Java、JavaScript等编程语言，流利的日语沟通能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '实习生',
        //         //         designation: '对日',
        //         //         location: 'Poland',
        //         //         description: '在读本科或硕士学生，对IT领域有浓厚兴趣，良好的学习能力和团队协作精神，基本的日语交流能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '项目经理',
        //         //         designation: '国内',
        //         //         location: 'CO Miego, AD, USA',
        //         //         description: '本科或以上学历，相关专业背景，3年以上项目管理经验，良好的沟通和协调能力，出色的团队领导和问题解决能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '项目组长',
        //         //         designation: '国内',
        //         //         location: 'Bangladesh',
        //         //         description: '本科或以上学历，相关专业背景，2年以上项目协调或团队领导经验，良好的组织和时间管理技能，良好的团队合作精神',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '开发工程师',
        //         //         designation: '国内',
        //         //         location: 'Poland',
        //         //         description: '计算机科学或相关专业背景，2年以上软件开发经验，熟练掌握前端或后端开发技能，良好的编程和问题解决能力',
        //         //     },
        //         //     {
        //         //         image: 'job',
        //         //         name: '实习生',
        //         //         designation: '国内',
        //         //         location: 'Poland',
        //         //         description: '在读本科或硕士学生，对IT行业有浓厚兴趣，良好的学习能力和沟通技巧，积极的工作态度和团队精神',
        //         //     },
        //         // ],
        //         testimonialData4:  {
        //             id: '01',
        //             form: 'Poland',
        //             description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
        //             name: 'John Due',
        //             subtitle: 'App Developer',
        //             image: 'testimonial-dark-01',
        //         },
        //         testimonialData5: [
        //             {
        //                 form: 'Poland',
        //                 description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
        //                 name: 'John Due',
        //                 subtitle: 'App Developer',
        //                 image: 'testimonial-dark-01'
        //             },
        //             {
        //                 form: 'Germany',
        //                 description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
        //                 name: 'John Due',
        //                 subtitle: 'App Developer',
        //                 image: 'testimonial-dark-02'
        //             },
        //             {
        //                 form: 'USA',
        //                 description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
        //                 name: 'Janen',
        //                 subtitle: 'App Developer',
        //                 image: 'testimonial-dark-03'
        //             },
        //         ]
        //     }
        // },
        computed: {
            testimonialData() {
                return [
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item1.name'),
                        designation: this.$t('testimonial.testimonialData.item1.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item1.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item2.name'),
                        designation: this.$t('testimonial.testimonialData.item2.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item2.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item3.name'),
                        designation: this.$t('testimonial.testimonialData.item3.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item3.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item4.name'),
                        designation: this.$t('testimonial.testimonialData.item4.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item4.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item5.name'),
                        designation: this.$t('testimonial.testimonialData.item5.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item5.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item6.name'),
                        designation: this.$t('testimonial.testimonialData.item6.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item6.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item7.name'),
                        designation: this.$t('testimonial.testimonialData.item7.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item7.description'),
                    },
                    {
                        image: 'job',
                        name: this.$t('testimonial.testimonialData.item8.name'),
                        designation: this.$t('testimonial.testimonialData.item8.designation'),
                        location: 'CO Miego, AD, USA',
                        description: this.$t('testimonial.testimonialData.item8.description'),
                    },
                ]
            }
        }
    }
</script>