<template>
    <!-- Start Copy Right Area  -->
    <div class="copyright-area copyright-style-one">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-8 col-sm-12 col-12">
                    <div class="copyright-left">
                        <ul class="ft-menu link-hover">
                            <li>
                                <router-link to="/">{{$t('bottom.businessConsulting')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/digital-agency">{{$t('bottom.digitalAgency')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/portfolio-full-width">{{$t('bottom.portfolioFullWidth')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/testimonial">{{$t('bottom.testimonial')}}</router-link>
                            </li>
                            <!-- <li>
                                <router-link to="/contact">{{$t('bottom.contact')}}</router-link>
                            </li> -->
                            <!-- <li>
                                <router-link to="/">{{$t('bottom.business')}}</router-link>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-4 col-sm-12 col-12">
                    <div class="copyright-right text-center text-lg-end">
                        <p class="copyright-text">© 思睿众合 {{ new Date().getFullYear() }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Copy Right Area  -->
</template>

<script>
    export default {
        name: 'Copyright'
    }
</script>