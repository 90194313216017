<template>
    <div :class="`rn-accordion-style ${accordionStyleClass} accordion`">
        <div class="accordion" :id="id">
            <div class="accordion-item card" v-for="(content, index) in accordionContent">
                <h2 class="accordion-header card-header" :id="`heading${content.id}`">
                    <button class="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            :data-bs-target="`#collapse${content.id}`"
                            :aria-expanded="index === activeCollapse ? 'true' : 'false'"
                            :aria-controls="`collapse${content.id}`"
                            @click="activeCollapse = index">
                        {{ content.title }}
                        <i class="collapse-icon"/>
                    </button>
                </h2>
                <div :id="`collapse${content.id}`"
                     class="accordion-collapse collapse"
                     :class="{'show': index === 0}"
                     :aria-labelledby="`heading${content.id}`"
                     :data-bs-parent="`#${id}`">
                    <div class="accordion-body card-body">
                        {{ content.description }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Accordion',
        props: {
            id: {
                type: String,
                default: 'accordionExample'
            },
            accordionStyleClass: {
                type: String
            },
            // accordionContent: {
            //     type: Array,
            //     default: function () {
            //         return [
            //             {
            //                 id: 'One',
            //                 title: this.$t('digitalAgency.subTitle1'),
            //                 description: '在我们的企业文化中，责任是我们的根本。我们坚信，作为一家企业，我们不仅要为股东创造价值，还要承担社会和环境的责任。我们积极采取可持续的商业实践，减少资源消耗，降低碳排放，并参与社区支持活动。我们对客户的责任意味着我们始终努力提供卓越的服务，满足其需求，并积极响应他们的反馈。我们深知，只有秉持责任，我们才能够持久繁荣，同时对社会和环境产生积极影响。'
            //             },
            //             {
            //                 id: 'Two',
            //                 title: this.$t('digitalAgency.subTitle2'),
            //                 description: '创新是我们企业文化的动力源泉。我们鼓励员工不断挑战现状，寻找创新的解决方案。我们相信，创新是取得成功的关键，因此我们不断投资于研发和技术，以确保我们的产品和服务始终保持领先地位。我们积极培养创造性思维，并鼓励员工提出新点子和方法，以满足客户不断变化的需求。创新贯穿于我们的DNA，是我们持续发展的基石。'
            //             },
            //             {
            //                 id: 'Three',
            //                 title: this.$t('digitalAgency.subTitle3'),
            //                 description: '超越是我们企业文化的核心。我们始终不满足于平庸，而是追求卓越。我们鼓励员工挑战自己的极限，不断提高自己的技能和能力，以达到更高的目标。我们相信，只有在不断挑战和超越自己的过程中，我们才能够实现真正的成就。超越是我们对自己的要求，也是我们对客户的承诺，以提供卓越的服务和解决方案。'
            //             },
            //             {
            //                 id: 'Four',
            //                 title: this.$t('digitalAgency.subTitle4'),
            //                 description: '共享是我们企业文化的重要组成部分。我们坚信，合作和共享知识是成功的关键。我们鼓励员工之间的协作，与合作伙伴建立紧密的关系，以实现共同的目标。我们积极分享最佳实践和经验，以促进团队的成长和客户的成功。共享不仅仅是一种行为，更是一种文化，让我们共同前进，共同创造价值。'
            //             },
            //         ]
            //     }
            // }
        },
        data() {
            return {
                activeCollapse: 0
            }
        },
        computed: {
            accordionContent() {
                return [
                    {
                            id: 'One',
                            title: this.$t('digitalAgency.subTitle1'),
                            description: this.$t('digitalAgency.rightContent1')
                        },
                        {
                            id: 'Two',
                            title: this.$t('digitalAgency.subTitle2'),
                            description: this.$t('digitalAgency.rightContent2')
                        },
                        {
                            id: 'Three',
                            title: this.$t('digitalAgency.subTitle3'),
                            description: this.$t('digitalAgency.rightContent3')
                        },
                        {
                            id: 'Four',
                            title: this.$t('digitalAgency.subTitle4'),
                            description: this.$t('digitalAgency.rightContent4')
                        },
                ]
            }
        }
    }
</script>