<template>
    <!-- Start Google MAp -->
    <div class="google-map-style-1">
        <iframe src="https://map.baidu.com/poi/%E5%A4%A7%E8%BF%9E%E8%AE%BE%E8%AE%A1%E5%9F%8E/@13528108.998923354,4673952.810288563,21z?uid=aaece1c6331bc9e8c26c1474&info_merge=1&isBizPoi=false&ugc_type=3&ugc_ver=1&device_ratio=2&compat=1&pcevaname=pc4.1&querytype=detailConInfo&da_src=shareurl" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- End Google MAp -->
</template>

<script>
    export default {
        name: 'GoogleMap'
    }
</script>