<template>
    <Layout header-class="header-left-align" nav-alignment="right" :header-transparency="false" :show-top-header="false" buy-button-class="btn-default btn-small" :footer-style="3">
        <!-- Start Slider Area -->
        <!-- <div class="slider-area slider-style-1 variation-default height-850 bg_image"
             data-black-overlay="7"
             :style="{'background-image': `url(${require(`@/assets/images/bg/background1.jpg`)})`}">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="inner text-center">
                            <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                {{$t('businessConsulting.subtitle')}}
                            </span>
                            <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">{{$t('businessConsulting.title')}}</h1>
                            <p class="description" data-aos="fade-up" data-aos-delay="200">
                                {{$t('businessConsulting.description1')}}&nbsp;{{$t('businessConsulting.description2')}}
                            </p>
                            <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                                <router-link class="btn-default btn-medium round btn-icon" to="/digital-agency">
                                    {{$t('header.digitalAgency')}}
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                                <router-link class="btn-default btn-medium btn-border round btn-icon" to="/portfolio-full-width">
                                    {{$t('header.portfolioFullWidth')}}
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Slider Area -->

        <!-- Start Slider Area -->
        <div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
            <VueSlickCarousel v-bind="sliderSetting">
                <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
                    <div class="height-850 bg-overlay bg_image"
                         :style="{'background-image': `url(${require(`@/assets/images/banner/${slider.image}.jpg`)})`}">
                        <!-- <div class="container">
                            <div class="row row--30 align-items-center">
                                <div class="order-2 order-lg-1 col-lg-7">
                                    <div class="inner text-start">
                                        <h1 class="title" v-html="slider.title"/>
                                        <p class="description" v-html="slider.description"/>
                                        <div class="button-group mt--30">
                                            <a class="btn-default"
                                               href="#">
                                                PURCHASE NOW
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="inner text-center">
                                        <!-- <span class="subtitle" data-aos="fade-up" data-aos-delay="100">
                                            {{$t('businessConsulting.subtitle')}}
                                        </span> -->
                                        <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">{{$t('businessConsulting.title')}}</h1>
                                        <p class="description" data-aos="fade-up" data-aos-delay="200">
                                            {{$t('businessConsulting.description1')}}&nbsp;{{$t('businessConsulting.description2')}}
                                        </p>
                                        <div class="button-group" data-aos="fade-up" data-aos-delay="250">
                                            <router-link class="btn-default btn-medium round btn-icon" to="/digital-agency">
                                                {{$t('header.digitalAgency')}}
                                                <Icon name="arrow-right" size="20" icon-class="icon"/>
                                            </router-link>
                                            <router-link class="btn-default btn-medium btn-border round btn-icon" to="/portfolio-full-width">
                                                {{$t('header.portfolioFullWidth')}}
                                                <Icon name="arrow-right" size="20" icon-class="icon"/>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VueSlickCarousel>
        </div>
        <!-- End Slider Area -->

        <Separator/>

        <!-- Start Mission Area -->
        <div class="rn-company-mission-are rn-section-gap">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-6">
                        <div class="mission-title">
                            <h2 class="title theme-gradient" data-aos="fade-up">
                                {{$t('digitalAgency.title')}} <br>
                                <vue-typed-js :strings="[$t('digitalAgency.subTitle1'), $t('digitalAgency.subTitle2'), $t('digitalAgency.subTitle3'), $t('digitalAgency.subTitle4')]"
                                              :loop="true"
                                              :type-speed="80"
                                              :start-delay="1000"
                                              :back-speed="50">
                                    <b class="is-visible typing">{{$t('digitalAgency.subTitle1')}}</b>
                                </vue-typed-js>
                            </h2>
                            <p data-aos="fade-up">
                                {{$t('digitalAgency.leftContent1')}}
                            </p>
                            <p data-aos="fade-up">
                                {{$t('digitalAgency.leftContent2')}}
                            </p>
                            <p data-aos="fade-up">
                                {{$t('digitalAgency.leftContent3')}}
                            </p>
                            <!-- <div class="read-more-btn mt--50" data-aos="fade-up">
                                <router-link class="btn-default btn-icon"
                                             to="/contact">
                                    {{$t('header.contact')}}
                                    <Icon name="arrow-right" size="20" icon-class="icon"/>
                                </router-link>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-6 mt_md--30 mt_sm--30">
                        <Accordion
                            id="accordionExampleOne"
                            data-aos="fade-up"
                            data-aos-delay="60"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- Start Mission Area -->

        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gapBottom pt--100" id="section2">
            <div class="container">
                <ServiceOne
                    service-style="service__style--1 icon-circle-style"
                    text-align="center"
                    icon-size="39"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <Separator/>

        <!-- Start Portfolio Area  -->
        <!-- <div class="rwt-portfolio-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Business Projects"
                            title="Business Case Studies.!"
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Portfolio column="col-lg-4 col-md-6 col-12 mt--30" :portfolio-data="portfolioData"/>
            </div>
        </div> -->
        <!-- End Portfolio Area  -->

        <!-- <Separator/> -->

        <!-- Start Timeline Area  -->
        <!-- <div class="rwt-timeline-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Working Process"
                            title="Business Working Process."
                            description="We provide company and finance service for <br /> startups and company business."
                        />
                    </div>
                </div>
                <div class="row mt_dec--20">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Timeline Area -->

        <!-- Start Video Area -->
        <!-- <div class="rwt-video-area rn-section-gapBottom">
            <div class="container">
                <Video :video-data="videoData" :video-style="2" column="col-lg-12" data-aos="fade-up"/>
            </div>
        </div> -->
        <!-- End Video Area -->

        <!-- <Separator/> -->

        <!-- Start Elements Area -->
        <!-- <div class="rwt-testimonial-area rn-section-gap">
            <div class="container">
                <div class="row mb--20">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Client Feedback"
                            title="Our Clients Feedback."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <Testimonial :testimonial="testimonialData5" :testimonial-style="5" data-aos="fade-up"/>
            </div>
        </div> -->
        <!-- End Elements Area -->

        <!-- <Separator/> -->

        <!-- Start Blog Area -->
        <!-- <div class="blog-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle="Latest News"
                            title="Our Latest News."
                            description="We provide company and finance service for <br /> startups and company business."
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <div class="row row--15">
                    <div class="col-lg-4 col-md-6 col-sm-12 col-12 mt--30"
                         data-aos="slide-up"
                         data-aos-duration="800"
                         :data-aos-delay="100 + index"
                         v-for="(blog, index) in posts"
                         :key="index"
                         v-if="index < 3">
                        <BlogPost :blog="blog"/>
                    </div>
                </div>
            </div>
        </div> -->

        <Separator/>

        <!-- Start Service Area -->
        <div class="rn-service-area rn-section-gap">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <SectionTitle
                            text-align="center"
                            subtitle=""
                            :title="$t('businessConsulting.sectionTitle.title')"
                            :description="$t('businessConsulting.sectionTitle.description')"
                            data-aos="fade-up"
                        />
                    </div>
                </div>
                <ServiceFour
                    service-style="service__style--1 icon-circle-style with-working-process"
                    text-align="center"
                />
            </div>
        </div>
        <!-- End Service Area -->

        <!-- Start End Area -->

        <div class="rwt-timeline-area rn-section-gapBottom">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Timeline :timeline-data="timelineData" :timeline-style="3"/>
                    </div>
                </div>
            </div>
        </div>

        <Separator/>

        <!-- Start Elements Area -->
        <!-- <div class="rwt-callto-action-area rn-section-gapBottom">
            <div class="wrapper">
                <CallToAction
                    :style-type="5"
                    title="想要拥有属于自己的解决方案吗？"
                    subtitle=""
                    btn-text="联系我们"
                />
            </div>
        </div> -->
        <!-- End Elements Area -->

        <!-- <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="inner text-center">
                        <h1 class="title display-one" data-aos="fade-up" data-aos-delay="150">
                            {{$t('businessConsulting.partners')}}
                        </h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <p class="mb--30" data-aos="fade-up">
                        {{$t('businessConsulting.partnerDescription')}}
                    </p>
                </div>
            </div>
        </div> -->

        <!-- Start Brand Area -->
        <!-- <div class="rwt-brand-area pb--60 pt--30">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 mt--10">
                        <Brand :brand-list="brandList" :brand-style="4"/>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- End Brand Area -->
    </Layout>
</template>

<script>
    import Layout from '../../components/common/Layout'
    import Icon from '../../components/icon/Icon'
    import Separator from '../../components/elements/separator/Separator'
    import SectionTitle from '../../components/elements/sectionTitle/SectionTitle'
    import ServiceOne from '../../components/elements/service/ServiceOne'
    import Portfolio from '../../components/elements/portfolio/Portfolio'
    import Timeline from '../../components/elements/timeline/Timeline'
    import Video from '../../components/elements/video/Video'
    import Testimonial from '../../components/elements/testimonial/Testimonial'
    import BlogPostMixin from '../../mixins/BlogPostMixin'
    import BlogPost from '../../components/blog/BlogPost'
    import CallToAction from '../../components/elements/callToAction/CallToAction'
    import Brand from '../../components/elements/brand/Brand'
    import VueSlickCarousel from 'vue-slick-carousel'
    import Accordion from '../../components/elements/accordion/Accordion'
    import ServiceFour from "../../components/elements/service/ServiceFour";

    export default {
        name: 'BusinessConsulting',
        components: {
            Brand,
            ServiceOne,
            CallToAction,
            BlogPost,
            Testimonial,
            Video,
            Timeline,
            Portfolio,
            SectionTitle,
            Separator,
            Icon,
            Layout,
            ServiceFour,
            Accordion,
            VueSlickCarousel,
        },
        mixins: [BlogPostMixin],
        data() {
            return {
                sliderSetting: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 800,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 993,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                            }
                        },
                        {
                            breakpoint: 481,
                            settings: {
                                slidesToShow: 1,
                            }
                        }
                    ]
                },
                brandList: [
                    {
                        image: require(`@/assets/images/brand/hezuohuoban1.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban2.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban3.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban4.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban5.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban6.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban7.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban8.png`)
                    },
                    {
                        image: require(`@/assets/images/brand/hezuohuoban9.png`)
                    },

                ],
                sliderData: [
                    {
                        image: 'banner1',
                        title: 'Digital Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                    {
                        image: 'banner2',
                        title: 'Creative Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                    {
                        image: 'banner3',
                        title: 'Startup Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                    {
                        image: 'banner4',
                        title: 'Startup Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                    {
                        image: 'banner5',
                        title: 'Startup Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                    {
                        image: 'banner6',
                        title: 'Startup Agency.',
                        description: 'We help our clients succeed by creating brand identities, digital experiences, and print materials.'
                    },
                ],
                // timelineData: [
                //     {
                //         id: '1',
                //         date: 'Step-1',
                //         title: 'Your working process you can present.',
                //         description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                //         image: 'timeline-01',
                //         workingStep: [
                //             {
                //                 stepTitle: 'Gain your works',
                //                 stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                //             },
                //             {
                //                 stepTitle: 'Working process',
                //                 stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                //             },
                //         ]
                //     },
                //     {
                //         id: '2',
                //         date: 'Step-2',
                //         title: 'Our compnay working process to present.',
                //         description: 'Lorem ipsum dolor sit amet at — consectetur adipisicing elit. Hic, expedita. Incidunt laboriosam.',
                //         image: 'timeline-02',
                //         workingStep: [
                //             {
                //                 stepTitle: 'Follow this process',
                //                 stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                //             },
                //             {
                //                 stepTitle: 'Working experience',
                //                 stepDescription: 'Lorem ipsum dolor sit amet at consectetur adipisicing',
                //             },
                //         ]
                //     },
                // ],
                videoData: [
                    {
                        thumb: require(`@/assets/images/bg/bg-image-4.jpg`),
                        src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
                        title: `Watch the creative process behind <br/> the digital marketing.`
                    }
                ],
                testimonialData5: [
                    {
                        form: 'Poland',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-01'
                    },
                    {
                        form: 'Germany',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'John Due',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-02'
                    },
                    {
                        form: 'USA',
                        description: 'What I am primarily looking for with new projects is a fit on both a visual and aesthetic level as well as on a personal level with the client.',
                        name: 'Janen',
                        subtitle: 'App Developer',
                        image: 'testimonial-dark-03'
                    },
                ],
                portfolioData:  [
                    {
                        id: 1,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        title: 'App Development',
                        subtitle: 'Branded client',
                        date: '30 May 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 2,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        title: 'Business Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 3,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        title: 'Photoshop Design',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'art',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-03.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 4,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        title: 'Native Application',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'development',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-04.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-04.jpg`)
                        }
                    },
                    {
                        id: 5,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        title: 'Vue Development',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-05.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-03.jpg`)
                        }
                    },
                    {
                        id: 6,
                        portfolioImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        image: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        title: 'App Installment',
                        subtitle: 'Awesome portfolio',
                        date: '01 March 2021',
                        client: 'Rainbow Themes',
                        category: 'design',
                        awards: ['2020 Design beautiful apps Finale Awards'],
                        excerpt: 'Design beautiful apps.',
                        body: `<p>In a typical Vue application, data is passed top-down (parent to child) via props, but such usage can be cumbersome for certain types of props (e.g. locale preference, UI theme).</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p> <br /> <p>that are required by many components within an application. Context provides a way to share values like these between components without having to explicitly pass a prop through every level of the tree.</p>`,
                        btn: {
                            buttonText: 'View Projects',
                            link: '/'
                        },
                        largeImage: require(`@/assets/images/portfolio/portfolio-06.jpg`),
                        subContent: {
                            title: 'Digital Marketing.',
                            subtext: 'We provide digital experience services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent2: {
                            title: 'App Development.',
                            subtext: 'We provide App Development services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        subContent3: {
                            title: 'Solution.',
                            subtext: 'We provide problem solutions services to startups and small businesses. We help our clients succeed by creating brand identities.'
                        },
                        gallery: {
                            imageOne: require(`@/assets/images/portfolio/portfolio-01.jpg`),
                            imageTwo: require(`@/assets/images/portfolio/portfolio-02.jpg`),
                            imageThree: require(`@/assets/images/portfolio/portfolio-06.jpg`)
                        }
                    }
                ]
            }
        },
        computed: {
            timelineData() {
                return [
                    {
                        id: '1',
                        title: this.$t('businessConsulting.timelineDataList.timelineDataItem1.title'),
                        description: this.$t('businessConsulting.timelineDataList.timelineDataItem1.description'),
                    },
                    {
                        id: '2',
                        title: this.$t('businessConsulting.timelineDataList.timelineDataItem2.title'),
                        description: this.$t('businessConsulting.timelineDataList.timelineDataItem2.description'),
                    },
                    {
                        id: '3',
                        title: this.$t('businessConsulting.timelineDataList.timelineDataItem3.title'),
                        description: this.$t('businessConsulting.timelineDataList.timelineDataItem3.description'),
                    },
                    {
                        id: '4',
                        title: this.$t('businessConsulting.timelineDataList.timelineDataItem4.title'),
                        description: this.$t('businessConsulting.timelineDataList.timelineDataItem4.description'),
                    },
                ]
            }
        }
    }
</script>