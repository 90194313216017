module.exports = {
  // 头部
  header: {
    businessConsulting: "首页",
    digitalAgency: "关于我们",
    portfolioFullWidth: "经典案例",
    testimonial: "诚聘英才",
    contact: "联系我们",
    business: "事业内容"
  },
  // 首页
  businessConsulting: {
    subtitle: "SOFTWARE TECHNOLOGY DEVELOPMENT",
    title: "大连思睿众合信息技术有限公司",
    description1: "以客户价值为导向",
    description2: "以技术铸就卓越品质",
    serviceList: {
      serviceItem1:{
        title: '定制化软件开发',
        description: '无论是企业级解决方案还是移动应用，我们提供定制化的软件开发，根据您的需求量身打造，以满足您独特的业务要求。'        
      },
      serviceItem2:{
        title: 'Web应用开发',
        description: '我们设计和开发强大的Web应用，结合最新的前端和后端技术，为您创造出令人惊叹的在线体验。'        
      },
      serviceItem3:{
        title: '移动应用开发',
        description: '利用我们的移动应用开发专长，将您的想法转化为高度功能的Android和iOS应用，让您的用户随时随地享受您的服务。'        
      },
      serviceItem4:{
        title: '技术咨询与解决方案',
        description: '我们的专业团队可以为您提供技术咨询，帮助您选择最适合您业务需求的技术解决方案，以实现您的业务目标。'        
      },
      serviceItem5:{
        title: '系统集成与现有应用升级',
        description: '无论您需要将不同系统整合还是升级现有应用，我们的专业团队可以帮助您实现平稳的过渡和高效的系统升级。'        
      },
      serviceItem6:{
        title: '用户体验设计',
        description: '我们将用户体验置于首位，以创新的设计和用户友好的界面为您的应用增色。我们相信出色的用户体验是吸引和留住用户的关键。'        
      },
    },
    sectionTitle:{
      title: '为什么选择我们',
      description: '在数字化时代，选择我们意味着选择卓越的专业知识、前沿的技术实力以及客户导向的服务。我们与业界领先的合作伙伴合作，提供高度定制的解决方案，确保项目交付卓越并注重安全和可靠性。我们不仅满足当下需求，还致力于可持续发展和社会责任。选择我们，是为了与成功、创新和卓越同行。',
    },
    serviceFourList:{
      serviceFourItem1:{
        title: '卓越的专业知识',
        description: '我们拥有多年来积累的深厚行业知识，为您提供卓越的解决方案。'        
      },
      serviceFourItem2:{
        title: '创新的技术实力',
        description: '我们持续采用最新技术，为您构建创新性的解决方案。'        
      },
      serviceFourItem3:{
        title: '客户导向的服务',
        description: '客户满意度是我们的首要任务，我们全力以赴，以满足您的需求。'        
      },
      serviceFourItem4:{
        title: '强大的合作伙伴关系',
        description: '我们与业界顶尖的合作伙伴合作，为您提供广泛的支持和资源。'        
      },
      serviceFourItem5:{
        title: '高度定制的解决方案',
        description: '我们深入了解您的需求，为您提供量身定制的解决方案。'        
      },
      serviceFourItem6:{
        title: '卓越的项目交付',
        description: '我们始终如一地按时交付项目，确保高质量的成果。'        
      },
      serviceFourItem7:{
        title: '安全和可靠性',
        description: '我们致力于确保您的数据和业务始终安全可靠。'        
      },
      serviceFourItem8:{
        title: '可持续发展承诺',
        description: '我们关注环境和社会责任，积极追求可持续发展的解决方案。'        
      },
    },
    timelineDataList: {
      timelineDataItem1:{
        title: '知识',
        description: '在这一步，我们深入了解您的需求，收集并整理相关的信息和知识。我们相信，真正的洞察力源自对问题的深刻理解，因此，我们努力确保在开始任何项目之前，我们都拥有必要的知识和背景。这有助于我们为您提供更智慧、更具创造性的解决方案。'        
      },
      timelineDataItem2:{
        title: '作业',
        description: '这一步是我们团队动手开始工作的阶段。我们的专业人员将知识转化为实际行动，展开各项任务和工作。通过高效的协作和技术专业知识，我们努力确保每一项工作都按计划顺利进行，以实现最佳结果。'        
      },
      timelineDataItem3:{
        title: '方案',
        description: '在这个阶段，我们精心构建解决方案，以满足您的需求和目标。我们的专家团队将工作成果转化为具体的解决方案，这些解决方案旨在为您提供卓越的价值和可持续的竞争优势。我们坚信，每一个挑战都有一个创新的解决方案等待着发现。'        
      },
      timelineDataItem4:{
        title: '过程',
        description: '这一步是我们的工作流程的关键组成部分。我们精心设计和管理项目的各个方面，确保高质量、高效率和可持续性。透明度和沟通在整个过程中都至关重要，因此我们与您保持密切联系，确保您随时了解项目进展情况。'        
      },
    },
    partners: "合作伙伴",
    partnerDescription: "在合作伙伴关系中，我们不仅仅是技术上的合作伙伴，更是战略上的伙伴。我们与合作伙伴共同制定战略计划，共享资源和知识，以应对不断变化的市场需求。我们的合作伙伴的专业知识和行业洞察力使我们能够为客户提供度身定制的解决方案，帮助他们实现业务目标，提高效率并降低成本。无论是面向大型企业还是中小型企业，我们的合作伙伴都是我们成功的关键之一，因为他们与我们一同构建了一个强大的IT生态系统，为客户创造更多价值。",
  },
  // 关于我们
  digitalAgency: {
    title: "企业文化",
    subTitle1: "责任",
    subTitle2: "创新",
    subTitle3: "超越",
    subTitle4: "共享",
    leftContent1: "在我们的企业文化中，责任代表了我们对社会、环境和客户的承诺。我们始终坚持道义和诚信，确保我们的业务运营是可持续的、透明的，对环境友好，并对客户负责。责任是我们企业的支柱，也是我们对未来的承诺，以创造更美好的明天。",
    leftContent2: "创新则代表了我们对不断进步的执着追求。我们不满足于过去的成就，而是勇敢地探索新的可能性。创新贯穿于我们的产品、服务和业务流程，激发着员工的创造力，使我们能够在快速变化的市场中保持竞争力。",
    leftContent3: "这四个主题——责任、创新、超越和共享——不仅仅是我们的企业文化的一部分，更是我们每天工作的指导原则。它们激励我们成为更好的企业，为客户提供更优质的解决方案，并在全球社会中产生积极的影响。我们引以为傲地传承着这一文化，坚信它将继续塑造我们的未来。",
    rightContent1: "在我们的企业文化中，责任是我们的根本。我们坚信，作为一家企业，我们不仅要为股东创造价值，还要承担社会和环境的责任。我们积极采取可持续的商业实践，减少资源消耗，降低碳排放，并参与社区支持活动。我们对客户的责任意味着我们始终努力提供卓越的服务，满足其需求，并积极响应他们的反馈。我们深知，只有秉持责任，我们才能够持久繁荣，同时对社会和环境产生积极影响。",
    rightContent2: "创新是我们企业文化的动力源泉。我们鼓励员工不断挑战现状，寻找创新的解决方案。我们相信，创新是取得成功的关键，因此我们不断投资于研发和技术，以确保我们的产品和服务始终保持领先地位。我们积极培养创造性思维，并鼓励员工提出新点子和方法，以满足客户不断变化的需求。创新贯穿于我们的DNA，是我们持续发展的基石。",
    rightContent3: "超越是我们企业文化的核心。我们始终不满足于平庸，而是追求卓越。我们鼓励员工挑战自己的极限，不断提高自己的技能和能力，以达到更高的目标。我们相信，只有在不断挑战和超越自己的过程中，我们才能够实现真正的成就。超越是我们对自己的要求，也是我们对客户的承诺，以提供卓越的服务和解决方案。",
    rightContent4: "共享是我们企业文化的重要组成部分。我们坚信，合作和共享知识是成功的关键。我们鼓励员工之间的协作，与合作伙伴建立紧密的关系，以实现共同的目标。我们积极分享最佳实践和经验，以促进团队的成长和客户的成功。共享不仅仅是一种行为，更是一种文化，让我们共同前进，共同创造价值。",
  },
  // 经典案例
  portfolioFullWidth: {
    title: "互联网+行业解决方案",
    subTitle: "INTERNET + INDUSTRY SOLUTION",
    portfolioItems: {
      item1: {
        title: '享空间',
        subtitle: '瑜伽平台',
        category: '瑜伽平台',
        body: `<p>一个专业瑜伽平台集店家加盟，瑜伽视频分享，会员约课，周边产品电商为一体。`,
      },
      item2: {
        title: 'SeMS管理系统',
        subtitle: '飞行安保管理系统',
        category: '飞行安保管理系统',
        body: `<p>航空安保管理体系（SeMS）是为适应世界民航安保管理发展趋势而提出的一种提高民航安保水平的管理方法，这种管理方法从系统安全的角度出发，在法规管理的基础上对现行的航空安保管理体系进行整合。
        民航安检SeMS质量监察电子系统是面向民航安保质量监察工作的信息采集与展示系统，采用云服务技术对质量监察数据进行移动采集、传输、储存、操作和维护，通过数据模型对各类数据进行分析并展示，重点实现监察工作记录电子化、质量安全管控信息化、数据处理分析智能化，解决公司当前质量监察工作的纸质台账问题及安全管理工作的实际需求，助于管理层调度指挥和辅助决策。同时系统支持移动app功能，实现手持移动终端和电脑终端实时录入、接收和数据查询。
        系统具有完善的系统管理工具，为用户自行维护提供方便；系统便于扩展和系统集成；系统具有完备的安全防护体系，以保障协同管理云系统数据的信息安全。
        产品包括web端管理系统和移动端操作系统。`,
      },
      item3: {
        title: '手机银行',
        subtitle: '金融应用程序',
        category: '金融应用程序',
        body: `<p>手机银行App让用户轻松查看他们的银行账户余额和交易历史记录。用户可以实时监控他们的资金流动，确保账户的财务状况始终受到控制`,
      },
    }
  },
  // 诚聘英才
  testimonial: {
    title: "招聘职位",
    testimonialData: {
      item1: {
        image: 'job',
        name: '项目经理',
        designation: '对日',
        location: 'CO Miego, AD, USA',
        description: '本科或以上学历，相关专业背景，3年以上项目管理经验，流利的日语沟通能力，出色的团队合作和领导能力',
      },
      item2: {
        image: 'job',
        name: '项目组长',
        designation: '对日',
        location: 'Bangladesh',
        description: '本科或以上学历，相关专业背景，2年以上项目协调或团队领导经验，流利的日语沟通能力，良好的问题解决和决策能力',
      },
      item3: {
        image: 'job',
        name: '开发工程师',
        designation: '对日',
        location: 'Poland',
        description: '计算机科学或相关专业背景，2年以上软件开发经验，熟练掌握Java、JavaScript等编程语言，流利的日语沟通能力',
      },
      item4: {
        image: 'job',
        name: '实习生',
        designation: '对日',
        location: 'Poland',
        description: '在读本科或硕士学生，对IT领域有浓厚兴趣，良好的学习能力和团队协作精神，基本的日语交流能力',
      },
      item5: {
        image: 'job',
        name: '项目经理',
        designation: '国内',
        location: 'CO Miego, AD, USA',
        description: '本科或以上学历，相关专业背景，3年以上项目管理经验，良好的沟通和协调能力，出色的团队领导和问题解决能力',
      },
      item6: {
        image: 'job',
        name: '项目组长',
        designation: '国内',
        location: 'Bangladesh',
        description: '本科或以上学历，相关专业背景，2年以上项目协调或团队领导经验，良好的组织和时间管理技能，良好的团队合作精神',
      },
      item7: {
        image: 'job',
        name: '开发工程师',
        designation: '国内',
        location: 'Poland',
        description: '计算机科学或相关专业背景，2年以上软件开发经验，熟练掌握前端或后端开发技能，良好的编程和问题解决能力',
      },
      item8: {
        image: 'job',
        name: '实习生',
        designation: '国内',
        location: 'Poland',
        description: '在读本科或硕士学生，对IT行业有浓厚兴趣，良好的学习能力和沟通技巧，积极的工作态度和团队精神',
      },
    }
  },
  // 联系我们
  contact: {
    phone: {
      title:"联络电话",
      phoneName1: "韩磊",
      phoneName2: "宫明哲"
    },
    email: {
      title:"邮箱地址",
      emailAddress:"lei.han@dl-dev.net",
    },
    address: {
      title:"位置",
      address:"大连市高新园区七贤岭爱贤街10号大连设计城A座412",
    },
    partners: {
      title:"合作伙伴",
      partnerName1:"東森テクノロジー株式会社",
      partnerName2:"苏州派维斯信息科技有限公司",
    },
  },
  // 底部
  bottom: {
    businessConsulting: "首页",
    digitalAgency: "关于我们",
    portfolioFullWidth: "经典案例",
    testimonial: "诚聘英才",
    contact: "联系我们",
    business: "事业内容"
  },
  // 共通
  common: {
    contactUs: "联系我们",
    return: "返回"
  }
}