import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from './zh'
import ja from './ja'

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh',
  messages: {
    'zh': zh,
    'ja': ja
  }
})

export default i18n